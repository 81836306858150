<template>
  <b-card
    no-body
    class="custom-border-top-1px reports"
  >
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <div>
            <b-row class="match-height">
              <b-col
                cols="12"
                lg="6"
                class="mb-1 mb-lg-0"
              >
                <div>
                  <b-badge
                    variant="success"
                    class="font-small-4 px-2 py-50"
                  >
                    THÔNG TIN
                  </b-badge>
                </div>
                <hr>
                <div
                  v-if="data.user"
                  class="info-wrapper"
                >
                  <span class="info-label">Họ tên:</span>
                  <span class="info-value text-primary">{{ data.user.fullname }}</span>

                  <span class="info-label">SĐT:</span>
                  <span class="info-value text-primary">{{ data.user.phone }}</span>

                  <span class="info-label">Email:</span>
                  <span class="info-value text-primary">{{ data.user.email }}</span>

                  <span
                    v-if="data.user.department"
                    class="info-label"
                  >Đội:</span>
                  <span
                    v-if="data.user.department"
                    class="info-value text-primary"
                  >{{ data.user.department.name }}</span>

                  <span class="info-label">Chức danh:</span>
                  <span class="info-value text-primary">{{ data.user.job_title_code }}</span>
                </div>

                <div
                  class="info-wrapper border-top mt-1 pt-1"
                >
                  <span class="info-label">Số ngày công tối đa:</span>
                  <span class="info-value text-primary">{{ formatNumber(userRating && userRating.working_day_count || 0) }}</span>

                  <span class="info-label">Tổng điểm:</span>
                  <span class="info-value text-primary">{{ formatNumber(userRating && userRating.total_rating_point || 0) }}</span>

                  <span class="info-label">Tổng ngày thực hiện:</span>
                  <span class="info-value text-primary">{{ formatNumber(userRating && userRating.total_rows || 0) }}</span>

                  <span class="info-label">Điểm trung bình:</span>
                  <span class="info-value text-primary">{{ formatNumber(userRating && userRating.average_rating_point || 0, 3) }}</span>
                </div>
              </b-col>
              <div
                v-if="data.user"
                class="mb-1 mb-lg-0 d-flex flex-column"
              >
                <b-avatar
                  size="150px"
                  class="avatar-border-2 mb-1 box-shadow-1 badge-minimal user-avatar"
                  :src="data.user.avatar"
                  rounded
                  variant="light-primary"
                  alt="Ảnh đại diện"
                  :text="data.user.fullname && data.user.fullname.split(' ').pop()"
                >
                  <feather-icon
                    v-if="!data.user.avatar"
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>

                <b-button
                  variant="primary"
                  @click="triggerUploadAvatar"
                >
                  <feather-icon
                    size="16"
                    icon="ImageIcon"
                    class="mr-50"
                  />
                  <span class="mr-25 align-middle">Đổi avatar</span>
                  <uploads
                    id="uploads"
                    ref="uploads"
                    type="button"
                    class="hidden-all-item d-none upload-image"
                    :files="files"
                    :options="{textDisplay: 'Tải ảnh lên'}"
                    :method-name="'uploadAvatar'"
                    @file-uploaded="handleUploaded"
                    @file-removed="handleRemoved"
                    @all-done="handleAllDone"
                  />
                </b-button>
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCol, BRow, VBTooltip, BAvatar, BButton,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import Uploads from '@core/components/uploads/Uploads.vue'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import http from '@/global/http'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BAvatar,
    Uploads,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    userRating: {
      type: Object,
      default: () => {
      },
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    countByStatus() {
      if (!this.data || !this.data.count_by_status || !this.data.count_by_status.length) return []
      const { list } = this.$status
      const cbs = {}
      list.forEach(item => {
        cbs[item.value] = {
          ...item,
          count: 0,
        }
      })
      this.data.count_by_status.forEach(({
        count,
        status,
      }) => {
        cbs[status].count = count
      })
      return Object.values(cbs) || []
    },
    hosoByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hs') || []
    },
    hopdongByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hd') || []
    },
  },
  methods: {
    handleUploaded(files = []) {
      this.files.push(...files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAllDone() {
      const last = this.files[this.files.length - 1] || {}
      if (this.$route.name === 'reports-user') {
        this.$action(this.updateUser(Object.assign(this.data.user, { avatar: last.thumbnail })))
      }
      if (this.$route.name === 'reports-me') {
        this.$action(this.updateMe(Object.assign(this.data.user, { avatar: last.thumbnail })))
      }
    },
    triggerUploadAvatar() {
      this.$refs.uploads.$el.click()
    },
    updateUser(data) {
      return http.api(store.dispatch('user/updateUser', data))
    },
    updateMe(data) {
      return http.api(store.dispatch('user/updateMe', data), res => {
        localStorage.setItem('userData', JSON.stringify(res.data))
      })
    },
  },
  setup() {
    const USER_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
    })

    const files = ref([])
    const chartReportsContract = ref({
      series: [85, 16, 50, 50],
      chartOptions: {
        legend: {
          show: false,
        },
        colors: [
          '#ffe700',
          '#00d4bd',
          '#826bf8',
          '#2b9bf4',
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // số hồ sơ
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '30%',
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '1.2rem',
                  fontFamily: 'Montserrat',
                  color: '#756bc9',
                  offsetY: 10,
                  fontWeight: 700,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                },
              },
            },
          },
        },
        labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        responsive: [
          {
            breakpoint: 1025,
            options: {
              chart: {
                height: 240,
              },
            },
          },
          {
            breakpoint: 769,
            options: {
              chart: {
                height: 200,
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
      },
    })

    return {
      files,
      chartReportsContract,
    }
  },
}
</script>

<style lang="scss">
.report-overview-wrapper {
  border: 1px solid gainsboro;
  border-radius: 1rem;
  padding: 1.5rem;
  height: 100%;
}
</style>
