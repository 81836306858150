<template>
  <b-card
    no-body
    class="custom-border-top-1px reports"
  >
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <div>
            <b-row class="match-height">
              <b-col
                cols="12"
                lg="6"
                class="mb-1 mb-lg-0"
              >
                <div
                  class="report-overview-wrapper"
                >
                  <b-badge
                    variant="success"
                    class="font-small-4 px-2 py-50"
                  >
                    HỒ SƠ
                  </b-badge>
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div
                        v-for="(c, idx) in hosoByStatus"
                        :key="idx"
                        class="mb-75"
                      >
                        <strong
                          class="minw-30px d-inline-block"
                          :class="'text-' + c.value"
                        >{{ c.count }}</strong>
                        <b-badge
                          class="font-small-3 cursor-pointer"
                          :class="'bg-light-'+c.value + ' text-'+c.value"
                          @click="goContractsList({filter: JSON.stringify({statusFilter: c.value})})"
                        >
                          {{ c.label }}
                        </b-badge>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex flex-column gap-50"
                    >
                      <div class="d-flex justify-content-between font-weight-bolder">
                        <span>Tổng HS:</span>
                        <strong>{{ hosoByStatus.reduce((total, current) => total+parseFloat(current.count), 0) }}</strong>
                      </div>
                      <div class="d-flex justify-content-between font-weight-bolder">
                        <span>Tổng giá trị:</span>
                        <strong>{{ formatNumber(data.original_total - data.contract_original_total) }}</strong>
                      </div>
                      <div class="text-personal d-flex justify-content-between">
                        <span>Cá nhân:</span>
                        <strong>{{ hosoCategory.personal || 0 }}</strong>
                      </div>
                      <div class="text-company d-flex justify-content-between">
                        <span>Doanh nghiệp:</span>
                        <strong>{{ hosoCategory.company || 0 }}</strong>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="mb-1 mb-lg-0"
              >
                <div
                  class="report-overview-wrapper"
                >
                  <b-badge
                    variant="danger"
                    class="font-small-4 px-2 py-50"
                  >
                    HỢP ĐỒNG
                  </b-badge>
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    ><div
                      v-for="(c, idx) in hopdongByStatus"
                      :key="idx"
                      class="mb-75"
                    >
                      <strong
                        class="minw-30px d-inline-block"
                        :class="'text-' + c.value"
                      >{{ c.count }}</strong>
                      <b-badge
                        class="font-small-3 cursor-pointer"
                        :class="'bg-light-'+c.value + ' text-'+c.value"
                        @click="goContractsList({filter: JSON.stringify({statusFilter: c.value})})"
                      >
                        {{ c.label }}
                      </b-badge>
                    </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex flex-column gap-50"
                    >
                      <div class="d-flex justify-content-between font-weight-bolder">
                        <span>Tổng HĐ:</span>
                        <strong>
                          {{ hopdongByStatus.reduce((total, current) => total + parseFloat(current.count), 0) }}
                        </strong>
                      </div>
                      <div class="d-flex justify-content-between font-weight-bolder">
                        <span>Tổng giá trị:</span>
                        <strong>{{ formatNumber(data.contract_original_total) }}</strong>
                      </div>
                      <div class="text-personal d-flex justify-content-between">
                        <span>Cá nhân:</span>
                        <strong>{{ hopdongCategory.personal || 0 }}</strong>
                      </div>
                      <div class="text-company d-flex justify-content-between">
                        <span>Doanh nghiệp:</span>
                        <strong>{{ hopdongCategory.company || 0 }}</strong>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>Có kế hoạch:</span>
                        <strong>{{ hopdongHasPlan.hasPlan || 0 }}</strong>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>Chưa có kế hoạch</span>
                        <strong>{{ hopdongHasPlan.noPlan || 0 }}</strong>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    countByStatus() {
      if (!this.data || !this.data.count_by_status || !this.data.count_by_status.length) return []
      const { list } = this.$status
      const cbs = {}
      list.forEach(item => {
        cbs[item.value] = {
          ...item,
          count: 0,
        }
      })
      this.data.count_by_status.forEach(({ count, status }) => {
        cbs[status].count = count
      })
      return Object.values(cbs) || []
    },
    hosoRefs() {
      if (!this.data.report_know_with
          || !this.data.report_know_with.length
          || !this.data.report_know_with_contract
          || !this.data.report_know_with_contract.length) {
        return []
      }
      const r = {}
      this.data.report_know_with.forEach(item => {
        r[item.info_how_to_know_me] = r[item.info_how_to_know_me] ? (r[item.info_how_to_know_me] + item.count) : item.count
      })
      this.data.report_know_with_contract.forEach(item => {
        r[item.info_how_to_know_me] = r[item.info_how_to_know_me] ? r[item.info_how_to_know_me] - item.count : r[item.info_how_to_know_me]
      })
      const res = Object.keys(r).filter(key => r[key]).map(key => ({ info_how_to_know_me: key, count: r[key] }))
      return res
    },
    hopdongRefs() {
      if (!this.data.report_know_with_contract
          || !this.data.report_know_with_contract.length) {
        return []
      }
      return this.data.report_know_with_contract || []
    },
    hosoHasPlan() {
      const p = {
        hasPlan: 0,
        noPlan: 0,
      }
      if (!this.data.count_by_is_has_plan
          || !this.data.count_by_is_has_plan.length) {
        return p
      }
      p.noPlan = this.data.count_by_is_has_plan.find(item => !item.is_has_plan)?.count
          - this.hopdongHasPlan.hasPlan
      p.hasPlan = this.data.total_count - this.data.contract_total_count - p.hasPlan
      return p
    },
    hopdongHasPlan() {
      const p = {
        hasPlan: 0,
        noPlan: 0,
      }
      if (!this.data.total_count_by_is_has_plan_contract
          || !this.data.total_count_by_is_has_plan_contract.length) {
        return p
      }
      p.noPlan = this.data.total_count_by_is_has_plan_contract.find(item => !item.is_has_plan)?.count
      p.hasPlan = this.data.total_count_by_is_has_plan_contract - p.noPlan
      return p
    },
    hosoCategory() {
      const c = {
        personal: 0,
        company: 0,
      }
      if (!this.data.count_by_category
          || !this.data.count_by_category.length
          || !this.data.count_by_category_contract.length
          || !this.data.count_by_category_contract) {
        return c
      }
      c.personal = this.data.count_by_category.find(item => item.category === 'personal')?.count
          - this.data.count_by_category_contract.find(item => item.category === 'personal')?.count
      c.company = this.data.count_by_category.find(item => item.category === 'company')?.count
          - this.data.count_by_category_contract.find(item => item.category === 'company')?.count
      return c
    },
    hopdongCategory() {
      const c = {
        personal: 0,
        company: 0,
      }
      if (!this.data.count_by_category_contract
          || !this.data.count_by_category_contract.length) {
        return c
      }
      c.personal = this.data.count_by_category_contract.find(item => item.category === 'personal')?.count
      c.company = this.data.count_by_category_contract.find(item => item.category === 'company')?.count
      return c
    },
    hosoByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hs') || []
    },
    hopdongByStatus() {
      if (!this.countByStatus || !this.countByStatus.length) return []
      return this.countByStatus.filter(item => item.type === 'hd') || []
    },
  },
  methods: {
    goContractsList(query = {}) {
      this.$router.push({
        name: 'contracts-list',
        query,
      })
    },
  },
  setup() {
    const chartReportsContract = ref({
      series: [85, 16, 50, 50],
      chartOptions: {
        legend: {
          show: false,
        },
        colors: [
          '#ffe700',
          '#00d4bd',
          '#826bf8',
          '#2b9bf4',
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // số hồ sơ
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '30%',
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '1.2rem',
                  fontFamily: 'Montserrat',
                  color: '#756bc9',
                  offsetY: 10,
                  fontWeight: 700,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                },
              },
            },
          },
        },
        labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        responsive: [
          {
            breakpoint: 1025,
            options: {
              chart: {
                height: 240,
              },
            },
          },
          {
            breakpoint: 769,
            options: {
              chart: {
                height: 200,
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
      },
    })

    return {
      chartReportsContract,
    }
  },
}
</script>

<style lang="scss">
.report-overview-wrapper {
  border: 1px solid gainsboro;
  border-radius: 1rem;
  padding: 1.5rem;
  height: 100%;
}

.report-overview-right-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 1rem 0;
  font-size: 1.15rem;
}
</style>
