<template>
  <b-card
    class="custom-border-top-1px"
  >
    <b-card-header class="pl-0 pt-0">
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        BÁO CÁO DOANH THU
      </b-badge>
    </b-card-header>
    <b-table
      ref="refUsersListTable"
      class="position-relative"
      responsive
      bordered
      striped
      primary-key="id"
      show-empty
      empty-text="Không có kết quả nào"
      :items="reportDebts"
      :fields="tableColumnsReportsPKH"
    >
      <!-- Column: stt -->
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <!-- Column: ngày thu -->
      <template #cell(ngay-thu)="data">
        {{ formatDate(data.item.debt_collection && data.item.debt_collection.collect_at) }}
      </template>

      <!-- Column: mã hd -->
      <template #cell(ma-hd)="data">
        <span
          class="text-primary cursor-pointer"
          @click="gotoContractDetail(data.item)"
        >{{ data.item.contract && data.item.contract.contract_code }}</span>
      </template>

      <!-- Column: debt_collection_total -->
      <template #cell(debt_collection_total)="data">
        <div class="text-primary">
          <span class="text-primary">{{ formatNumber(data.item['debt_collection'].amount) }} đ</span>
        </div>
      </template>

      <!-- Column: money -->
      <template #cell(money)="data">
        <div class="text-primary">
          <span class="text-primary">{{ formatNumber(data.item['value']) }} đ</span>
        </div>
      </template>

      <!-- Column: actions -->
      <template #cell(actions)="data">
        <div class="text-right">
          <b-button
            size="sm"
            variant="primary"
            @click="showModalDebtDetail(data.item)"
          >Chi tiết
          </b-button>
        </div>
      </template>
    </b-table>

    <b-modal
      id="modal-debt-collection-detail"
      ref="modal-debt-collection-detail"
      ok-only
      ok-title="Ok"
      centered
      size="lg"
      header-text-variant="primary"
      :title="'Chi tiết doanh thu ngày ' + formatDate(currentModalDebtData.created_at) + ' của ' + (currentModalDebtData.contract || {}).contract_code"
    >
      <b-card-text>
        <br>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Số tiền thu được</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(currentModalDebtData.debt_collection.amount) }}</span>
                </div>
                <div>
                  <span class="debt-label">Phí DV</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ parseFloat(currentModalDebtData.debt_collection.fee_to_buy_original) }}%</span>
                </div>
                <div>
                  <span class="debt-label">Tổng PDV</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(currentModalDebtData.debt_collection.amount_to_buy_original) }} đ</span>
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <div>
                  <span class="debt-label">Chi đội</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(currentModalDebtData.debt_collection.amount_for_team) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Công đoàn</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(currentModalDebtData.debt_collection.syndicate_amount) }} đ</span>
                </div>
                <div>
                  <span class="debt-label">Lái xe</span>
                  <span
                    class="detail-text text-primary font-weight-bolder"
                  >{{ formatNumber(currentModalDebtData.debt_collection.driver_amount) }} đ</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BTable, VBTooltip, BBadge, BButton, BCardText, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCardHeader,
    BCard,
    BTable,
    BBadge,
    BButton,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reportDebts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showModalDebtDetail(data) {
      this.currentModalDebtData = data
      this.$refs['modal-debt-collection-detail'].show()
    },
    gotoContractDetail(c) {
      const id = c.contract_id
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
      })
    },
    goReportsDepartment(d) {
      console.log(d)
    },
  },
  setup() {
    const currentModalDebtData = ref({
      debt_collection: {},
    })
    return {
      currentModalDebtData,
      tableColumnsReportsPKH: [
        {
          label: 'STT',
          key: 'stt',
        },
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
        },
        {
          label: 'Mã HĐ',
          key: 'ma-hd',
        },
        {
          label: 'Số tiền thu nợ',
          key: 'debt_collection_total',
        },
        {
          label: 'Số tiền nhận được',
          key: 'money',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
.debt-label {
  min-width: 130px;
  display: inline-block;
}
</style>
