<template>
  <vue-dropzone
    :id="id"
    :ref="id"
    :options="dropzoneOptions"
    :duplicate-check="true"
    :class="!files.length? 'no-files' : ''"
    @vdropzone-file-added="handleFileAdded"
    @vdropzone-duplicate-file="handleDuplicateFile"
    @vdropzone-error="handleErrorFile"
    @vdropzone-success-multiple="handleSuccessMultiple"
    @vdropzone-success="handleSuccess"
    @vdropzone-removed-file="handleRemovedFile"
    @vdropzone-queue-complete="allDone"
    @vdropzone-sending="handleSending"
  />
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import { computed, ref } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: String,
      default: 'dropzone',
    },
    files: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: $themeConfig.app.urlUpload,
    },
    options: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    files: {
      handler() {
        this.loadPictures()
      },
      immediate: true,
    },
  },
  methods: {
    handleSending() {
    },
    loadPictures() {
      setTimeout(() => {
        if (this.files.length) {
          this.files.forEach(f => {
            if (this.filesNameExisted[f.name]) return // file existed
            this.filesNameExisted[f.name] = true
            if (this.$refs.uploads) {
              this.$refs.uploads.manuallyAddFile(f, f.large)
              this.$refs.uploads.dropzone.emit('thumbnail', f, f.thumbnail)
              this.$refs.uploads.dropzone.emit('complete', f)
            }
          })
        }
      }, 2000)
    },
    handleFileAdded(file) {
      this.filesNameExisted[file.name] = true
    },
    handleDuplicateFile(file) {
      this.$toastr(`File [ ${file.name} ] đã được tải lên trước đó!`, 'warning')
    },
    handleErrorFile(file, message) {
      this.$toastr(message, 'danger')
    },
    handleSuccess(file, response) {
      this.$emit('file-success', Object.assign(response, { name: file.name }))
    },
    handleSuccessMultiple(files, response) {
      const f = response.map((r, idx) => ({
        ...r,
        name: files[idx].name,
      }))
      this.$emit('file-uploaded', f)
    },
    handleRemovedFile(response) {
      this.$emit('file-removed', response)
    },
    allDone() {
      this.$emit('all-done')
    },
  },
  setup(props) {
    const filesNameExisted = ref({})
    const dropzoneOptions = computed({
      get: () => ({
        url: props.url,
        thumbnailWidth: 250,
        addRemoveLinks: false,
        acceptedFiles: 'image/*,application/pdf,.docx',
        // maxFilesize: 5,
        uploadMultiple: true,
        dictDefaultMessage: '<span class="text-primary font-medium-3"><i class="vs-icon feather icon-upload-cloud" /> CHỌN FILE</span>',
        ...props.options,
      }),
      set: () => {
      },
    })
    return {
      filesNameExisted,
      dropzoneOptions,
    }
  },
}
</script>
<style lang="scss">
@import 'vue2-dropzone/dist/vue2Dropzone.min';
</style>
