import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    dashboard: (ctx, q) => axiosIns.get(`/api.report/dashboard${q || ''}`),
    chart: (ctx, q) => axiosIns.get(`/api.report/chart${q || ''}`),
    sum: (ctx, q) => axiosIns.get(`/api.report/sum${q}`),
    sumMe: (ctx, q) => axiosIns.get(`/api.report/sum/me${q}`),
    sumDepartment: (ctx, q) => axiosIns.get(`/api.report/sum/department${q}`),
    user: (ctx, data) => axiosIns.get(`/api.report/user/${data.id}${data.q}`),
    me: (ctx, q) => axiosIns.get(`/api.report/user/me${q}`),
    creator: (ctx, q) => axiosIns.get(`/api.report/creator/me${q || ''}`),
    rating: (ctx, q) => axiosIns.get(`/api.work/report/rating${q}`),
    updateRatingTDPC: (ctx, data) => axiosIns.put('/api.work/rating/TDPC', data),
    fetchDepartmentReview: (ctx, q) => axiosIns.get(`/api.department-review${q || ''}`),
    fetchDepartmentVote: (ctx, { id, query }) => axiosIns.get(`/api.department-vote/${id}/top${query}`),
    fetchAllDepartmentVote: (ctx, query) => axiosIns.get(`/api.department-vote${query}`),
    createDepartmentReview: (ctx, data) => axiosIns.post('/api.department-review', data),
    updateDepartmentReview: (ctx, data) => axiosIns.put('/api.department-review', data),
    deleteDepartmentReview: (ctx, data) => axiosIns.delete('/api.department-review', data),
    exportTeamReportMember: (ctx, data) => axiosIns.get(`/api.report/department/${data.department_id}/export${data.q}`),
    fetchDebtCollectionReport: (ctx, q) => axiosIns.get(`/api.debt-collection/report/all${q}`),
  },
}
