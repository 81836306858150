// eslint-disable-next-line import/prefer-default-export
export function getBeforeMonth() {
  const currentDate = new Date()
  let month = currentDate.getMonth() // 0-11
  let year = currentDate.getFullYear()

  if (month === 0) {
    month = 11 // December of the previous year
    year -= 1
  } else {
    month -= 1
  }

  return { month: month + 1, year } // month + 1 to convert 0-11 to 1-12
}
